<template>
  <div class="v_message g-flex-column">
    <head-back :isBack="false">
      <template>
        <span>通知消息</span>
      </template>
    </head-back>
    <div class="v-message-list-contianer g-flex-column">
      <van-list class="v-message-list" v-show="messageList.length != 0" v-model="loading" :finished="finished"  finished-text="没有更多了"  @load="onLoad" :immediate-check="false">
        <div class="v-message-list-item" v-for="(item, index) in messageList" :key="index" @click="itemClick(item)">
          <!-- 顶部 -->
          <div class="v-message-item-top g-flex-align-center">
            <div class="v-message-item-top-type g-flex-align-center">
              <!-- 	类型 type 0-其他消息 1-任务消息 2-佣金消息 3-提现消息 -->
              <!-- 其他 -->
             <div class="item-top-type-icon item-top-type-icon-four" v-show="item.type == 0">
                <i class="iconfont iconmessage-fill"></i>
              </div>
              <!-- 任务 -->
              <div class="item-top-type-icon item-top-type-icon-three" v-show="item.type == 1">
                <i class="iconfont iconrenwu1"></i>
              </div>
              <!-- 返佣 -->
              <div class="item-top-type-icon item-top-type-icon-one" v-show="item.type == 2">
                <i class="iconfont icon80"></i>
              </div>
              <!-- 提现 -->
              <div class="item-top-type-icon item-top-type-icon-two" v-show="item.type == 3">
                <i class="iconfont icontixianjilu"></i>
              </div>
              
              <!-- 任务类型名称 -->
              <p class="v-message-item-top-type-title">{{item.title}}</p>
            </div>
          </div>

          <!-- 中间部分 -->
          <div class="v-message-item-middle g-flex-column">
            <h2 class="v-message-middle-title">{{item.content}}</h2>
            <!-- <h2 class="v-message-middle-msg">+3.00</h2> -->
          </div>

          <!-- 底部 -->
          <div class="v-message-item-bottom g-flex-align-center g-flex-justify-between">
            <div>
              <span class="v-message-item-bottom-time">时间: </span>
              <span class="v-message-item-bottom-seetext">{{ formatDate(item.create_time, 'yyyy-MM-dd hh:mm:ss') }}</span>
            </div>
            <i class="iconfont iconxiayige1"></i>
          </div>
        </div>
      </van-list>
      <NoList v-show="messageList.length == 0"/> 
    </div>
    <message-detail-pop ref="MessageDetailPop"/>
  </div>
</template>

<script>
  import HeadBack from '../../components/HeadBack.vue'
  import { formatDate } from '../../utils/index.js'
  import { PAGE, LIMIT } from '@/utils/constants.js'
  import MessageDetailPop from './MessageDetailPop.vue'
  import { apiGetMsgList } from '@/utils/api.js'
  export default {
    components: { HeadBack, MessageDetailPop },
    data() {
      return {
        num: 10,
        messageList: [],
        form: {
          page: PAGE,
          limit: LIMIT,
        },
        loading: false,
        finished: false
      }
    },
    created() {
      this.apiGetMsgListHandel()
    },
    methods: {
      formatDate,
      onLoad() {
        this.form.page++
        this.apiGetMsgListHandel()
      },
      // 获取消息列表
      async apiGetMsgListHandel() {
        const { success, data } = await apiGetMsgList(this.form)
        if(!success) return
        console.log(data)
        this.messageList = this.messageList.concat(data.list)
        this.loading = false
        this.finished = data.list.length < this.form.limit
      },
      itemClick(item) {
        console.log(item)
        // this.$refs.MessageDetailPop.onShow(true)
      }
    }
  }
</script>

<style lang="scss">
.v_message {
  background: #F1F1F1;
  .v-message-list-contianer {
    flex: 1;
    overflow: hidden;
    .v-message-list {
      flex: 1;
      overflow: auto;
      padding: 4px 10px;
      .v-message-list-item {
        padding: 15px 12px 0 12px;
        background: $white;
        border-radius: 8px;
        margin-top: 14px;
        .v-message-item-top {
          .v-message-item-top-type {
            flex: 1;
            .item-top-type-icon {
              width: 24px;
              height: 24px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              .iconfont {
                color: $white;
                font-size: 16px;
              }
              &.item-top-type-icon-one {
                background: #1989FA;
              }
              &.item-top-type-icon-two {
                background: #07C160;
              }
              &.item-top-type-icon-three {
                background:#FFAD2C;
              }
              &.item-top-type-icon-four {
                background: #41a5e7;
              }
            }

            .v-message-item-top-type-title {
              color: #121212;
              font-size: 16px;
              padding-left: 8px;
              flex: 1;
              @include textManyOverflow();
            }
          }

          .v-message-item-top-time {
            color: #898989;
            font-size: 12px;
            padding-right: 4px;
          }
        }

        .v-message-item-middle {
          padding: 20px 0;
          border-bottom: 1px solid #E5E5E5;
          .v-message-middle-title, .v-message-middle-msg {
            color: #242424;
            font-size: 14px;
            font-weight: bold;
            word-break: break-all;
            line-height: 24px;
            @include textManyOverflow(2);
          }
          .v-message-middle-msg {
            padding-top: 18px;
          }
        }

        .v-message-item-bottom {
          padding: 12px 0;
          color: #303030;
          font-size: 14px;
          .v-message-item-bottom-seetext {
            padding-left: 10px;
          }
          .iconfont {
            font-size: 12px;
            color: #7D929C;
          }
        }
      }
    }
  }

}
</style>