/**
 * @example
 * ```js
 * var string = new Date(time).format('yyyy-MM-dd hh:mm:ss')
 * ```
 * @param fmt
 * @return {*}
 */
Date.prototype.format = function (fmt) {
  let o = {
    'M+': this.getMonth() + 1, //月份
    'd+': this.getDate(), //日
    'h+': this.getHours(), //小时
    'm+': this.getMinutes(), //分
    's+': this.getSeconds(), //秒
    'q+': Math.floor((this.getMonth() + 3) / 3), //季度
    'S': this.getMilliseconds() //毫秒
  }
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
  for (let k in o)
    if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
  return fmt
}

// 数组去重
export const uniqueArr = (arr) => {
  return arr.filter((item, index) => {
    return arr.indexOf(item) === index
  })
}

// 日期格式化
export const formatDate = (timestamp, template = 'yyyy-MM-dd') => {
  let str = '' + timestamp
  // if (str.length < 10) return null
  if (str.length <= 10) timestamp *= 1000
  return new Date(timestamp).format(template)
}

// Math.formatFloat (5.39 - 5.34, 2)
// f 计算表达式
// digit 小数位数
// 处理js小数精度丢失的bug
Math.formatFloat = function (f, digit) {
  var m = Math.pow(10, digit);
  return Math.round(f * m) / m;
}



/**
 * @param {*} element 需要绑定事件的元素
 * @param {*} type    需要为元素绑定的事件类型
 * @param {*} fu      需要为元素绑定的事件
 */

export const addEventListener = (element,type,fu) => {
  if(element.addEventListener){
      element.addEventListener(type,fu,false);
  }else if(element.attachEvent){
      element.attachEvent("on"+type,fu);
  }else{
      element["on"+type] = fu;
  }
}

