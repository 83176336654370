<template>
  <div class="s_message_detail_pop">
    <van-popup v-model="show" :safe-area-inset-bottom="true" position="right" :style="{width: '100%', height: '100%' }" class="s-message-detail-container">
      <head-back @emitHeadBack="emitHeadBack">
        <template v-slot:returnText>
          <span>返回</span>
        </template>

         <template v-slot:default>
          <span>返佣详情</span>
        </template>
      </head-back>

      <div class="s-message-detail-content g-flex-column g-flex-align-center">
        <div class="s-message-detail-icon s-message-detail-icon-one g-flex-align-center g-flex-justify-center">
          <i class="iconfont icon80"></i>
        </div>

        <div class="s-meeage-detail-title">返佣金</div>

        <p class="s-message-detail-money">+3.00</p>

        <span class="s-message-detail-status">交易成功</span>

        <ul class="s-message-detail-card">
          <li class="s-message-detail-card-item g-flex-align-center">
            <span class="s-message-detail-card-item-title">交易方式</span>
            <div class="s-message-detail-card-item-text">返佣金</div>
          </li>
          <li class="s-message-detail-card-item g-flex-align-center">
            <span class="s-message-detail-card-item-title">创建时间</span>
            <div class="s-message-detail-card-item-text">2020-11-16 14:38</div>
          </li>
          <li class="s-message-detail-card-item g-flex-align-center">
            <span class="s-message-detail-card-item-title">订单号</span>
            <div class="s-message-detail-card-item-text">2020451235323151323223323221</div>
          </li>
        </ul>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import HeadBack from '../../components/HeadBack.vue'
  export default {
    components: { HeadBack },
    data() {
      return {
        show: false
      }
    },
    methods: {
      onShow(flag) {
        this.show = flag
      },
      emitHeadBack() {
        this.show = false
      }
    }
  }
</script>

<style lang="scss">
.s_message_detail_pop {
  .van-overlay {
    background-color: #ffffff;;
  }
  .s-message-detail-container {
    background: #F1F1F1;
    .s-message-detail-content {
      padding: 0 12px;
      .s-message-detail-icon {
        margin-top: 40px;
        width: 42px;
        height: 42px;
        border-radius: 50%;
        .iconfont {
          color: $white;
          font-size: 26px;
        }
        &.s-message-detail-icon-one {
          background: #1989FA;
        }
      }

      .s-meeage-detail-title {
        padding-top: 10px;
        font-size: 16px;
        color: #000000;
      }

      .s-message-detail-money {
        color: #363636;
        font-size: 26px;
        padding-top: 16px;
      }

      .s-message-detail-status {
        padding-top: 14px;
        font-size: 14px;
      }

      .s-message-detail-card {
        margin-top: 30px;
        align-self: stretch;
        background: $white;
        border-radius: 8px;
        padding: 20px 16px;
        .s-message-detail-card-item {
          padding-top: 22px;
          color: #797979;
          font-size: 14px;
          line-height: 18px;
          .s-message-detail-card-item-text {
            padding-left: 10px;
            color: #151515;
            flex: 1;
            text-align: right;
            word-break: break-all;
            letter-spacing: 1px;
          }
          &:nth-of-type(1) {
            padding-top: 0;
          }
        }
      }
    }
  } 
}
</style>